import React from "react"
import {Box, Flex, Link, Text, useColorMode} from "@chakra-ui/core";

const NavLink = ({ children, ...props }) => (
  <Link px={2} {...props}>
    {children}
  </Link>
);

const Header = () => {
  const { colorMode } = useColorMode();

  const bg = { light: "white", dark: "gray.800" };

  return (
    <Box
        pos="fixed"
        w="100%"
        zIndex="4"
        top="0"
        borderBottomWidth="2px"
        bg={bg[colorMode]}
    >
      <Flex
        w="100%"
        px={{
          md: '4rem',
          sm: '2rem',
          xs: '1rem'
        }}
        py={4}
        justifyContent="space-between"
        alignItems="center"
        maxWidth="1280px"
        mx="auto"
      >
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <Text pl={3} fontWeight="bold">
            Akuston <Box as="span" display={{ sm: 'initial', xs: 'none' }}>Łazowik Eugeniusz</Box>
          </Text>
        </Flex>
        <Box>
          <NavLink href="#lokalizacje">Punkty</NavLink>
          <NavLink href="#faq">Częste pytania</NavLink>
        </Box>
      </Flex>
    </Box>
  )
};

const CovidHeader = () => {
  const { colorMode } = useColorMode();

  const bg = { light: "white", dark: "gray.800" };

  return (
    <Flex
      pos="fixed"
      w="100%"
      px={5}
      py={4}
      justifyContent="center"
      alignItems="center"
      bg="red.900"
      borderBottomWidth="1px"
      zIndex="4"
      top="3.6rem"
      fontWeight="bold"
    >
      <Box>W związku z COVID-19 zapraszamy wyłącznie po umówieniu telefonicznym.</Box>
    </Flex>
  )
};

export { Header, CovidHeader };