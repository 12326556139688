import React from "react";
import {Accordion, AccordionHeader, AccordionItem, AccordionPanel, theme} from "@chakra-ui/core";
import { ThemeProvider, ColorModeProvider, CSSReset } from "@chakra-ui/core";
import { Box, Divider, Button, Grid, Heading, Flex, Image, Text, Link, useColorMode } from "@chakra-ui/core";
import { MdChildCare, MdHearing } from "react-icons/md";
import {FaLanguage, FaPlus} from "react-icons/fa";
import SEO from "../components/seo";
import {CovidHeader, Header} from "../components/header";

const mapsStyle = {
  light: "",
  dark: "&style=element:geometry%7Ccolor:0x1d2c4d&style=element:labels.text.fill%7Ccolor:0x8ec3b9&style=element:labels.text.stroke%7Ccolor:0x1a3646&style=feature:administrative.country%7Celement:geometry.stroke%7Ccolor:0x4b6878&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0x64779e&style=feature:administrative.province%7Celement:geometry.stroke%7Ccolor:0x4b6878&style=feature:landscape.man_made%7Celement:geometry.stroke%7Ccolor:0x334e87&style=feature:landscape.natural%7Celement:geometry%7Ccolor:0x023e58&style=feature:poi%7Celement:geometry%7Ccolor:0x283d6a&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x6f9ba5&style=feature:poi%7Celement:labels.text.stroke%7Ccolor:0x1d2c4d&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0x023e58&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x3C7680&style=feature:road%7Celement:geometry%7Ccolor:0x304a7d&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x98a5be&style=feature:road%7Celement:labels.text.stroke%7Ccolor:0x1d2c4d&style=feature:road.highway%7Celement:geometry%7Ccolor:0x2c6675&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x255763&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0xb0d5ce&style=feature:road.highway%7Celement:labels.text.stroke%7Ccolor:0x023e58&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x98a5be&style=feature:transit%7Celement:labels.text.stroke%7Ccolor:0x1d2c4d&style=feature:transit.line%7Celement:geometry.fill%7Ccolor:0x283d6a&style=feature:transit.station%7Celement:geometry%7Ccolor:0x3a4762&style=feature:water%7Celement:geometry%7Ccolor:0x0e1626&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x4e6d70&size=480x360"
};

const Feature = ({ title, icon, children, ...props }) => {
  return (
    <Box {...props}>
      <Flex
        rounded="full"
        size={12}
        bg="teal.500"
        align="center"
        justify="center"
      >
        <Box size={6} color="white" as={icon} />
      </Flex>
      <Heading as="h2" size="md" fontWeight="semibold" mt="1em" mb="0.5em">
        {title}
      </Heading>
      <Text>{children}</Text>
    </Box>
  );
};

const Container = props => (
  <Box width="full" maxWidth="1280px" mx="auto" px={6} {...props} />
);

const Question = ({ children, ...props }) => (
  <AccordionHeader>
    <Heading as="h3" size="sm" mt="1em" mb="0.2em" textAlign="left" {...props}>
      <Box size={3} color="white" display="inline-block" verticalAlign="baseline" as={FaPlus} /> {children}
    </Heading>
  </AccordionHeader>
);

const Answer = ({ children, ...props}) => (
  <AccordionPanel>
    <Text mb="1em" {...props}>
      {children}
    </Text>
  </AccordionPanel>
);

const Location = ({ children, city, mapsQuery, mapsLink }) => {
  const { colorMode } = useColorMode();

  return (
    <Box>
      <Heading as="h3" size="md">
        { city }
      </Heading>
      { children }

      <Link isExternal href={mapsLink}>
        <img
          src={"https://maps.googleapis.com/maps/api/staticmap" +
            `?markers=size:mid%7Ccolor:red%7C${mapsQuery}` +
            "&zoom=14" +
            "&size=300x300" +
            "&scale=2" +
            "&key=AIzaSyCq9wlvltE2H6RnuZK7dPoGHQbZJyjetbg" +
            mapsStyle[colorMode] }
          style={{
            width: "100%",
            maxWidth: "300px",
            marginTop: "1.25rem"
          }}
          alt={city + " mapa"}
          loading="lazy"
        />
      </Link>
    </Box>
  );
};

const IndexPage = () => {
  return ([
    <ThemeProvider theme={theme}>
      <SEO title="Aparaty słuchowe" />
      <ColorModeProvider value="dark">
        <CSSReset />
        <Header />
        <CovidHeader />
        <Box as="section" pt="13rem" pb={24}>
          <Container>
            <Box maxW="xl" mx="auto" textAlign="center">
              <Heading as="h1" size="xl" fontWeight="semibold">
                Akuston Łazowik Eugeniusz: <Box as="span" color="teal.500">Analityczna</Box> protetyka słuchu
              </Heading>
              <Text opacity="0.9" fontSize="xl" mt="6">
                Maksymalizujemy rozumienie mowy, TV, rozmów telefonicznych z użyciem zaawansowanego sprzętu i metodyki.
              </Text>

              <Box mt="6">
                <Button size="lg" as="a" m={2} variantColor="teal" href="tel:+48-601-677-713">
                  Zadzwoń: 601 677 713
                </Button>
                <Button size="lg" as="a" m={2} variantColor="teal" variant="outline" href="tel:+48-605-830-303">
                  Lub: 605 830 303
                </Button>
                <Button
                  as="a"
                  size="lg"
                  m={2}
                  href="#lokalizacje"
                >
                  Nasze lokalizacje
                </Button>
              </Box>
            </Box>
          </Container>

          <Divider my={16} />

          <Container>
            <Grid
              templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap={10}
              px={{ md: 12 }}
            >
              <Feature icon={FaLanguage} title="Rozumienie mowy">
                Naszym podstawowym celem jest zapewnienie jak najlepszego rozumienia
                mowy naszym klientom, zarówno żywej, z telewizora czy przy rozmowach telefonicznych.
              </Feature>
              <Feature icon={MdHearing} title="Nie zgadujemy">
                Używamy sprzętu i metod które pozwalają nam ustalić jak dokładnie
                zachowuje się konkretny aparat słuchowy na konkretnym uchu.
              </Feature>
              <Feature icon={MdChildCare} title="Dzieci">
                Mamy doświadczenie w pracy z dziećmi, współpracujemy z logopedą.
              </Feature>
            </Grid>
          </Container>

          <Divider my={16} />

          <Container>
            <Box maxW="xl" mx="auto">
              <Heading fontWeight="semibold" textAlign="center" mb="2em" id="lokalizacje">
                Nasze lokalizacje
              </Heading>
            </Box>

            <Grid
              templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
              gap={10}
              px={{ md: 12 }}
            >
              <Location
                city="Iława"
                mapsLink="https://goo.gl/maps/nSCBmR9omvwanh1T8"
                mapsQuery="Akuston+Lazowik+Eugeniusz+Ilawa"
              >
                Centrum Medyczne Meden, gabinety nr. 14 i 15<br/>
                Ireny Sendlerowej 8, 14-200 Iława<br/>
                <br/>
                pon. - pt. 9:00 - 14:00
              </Location>

              <Location
                city="Działdowo"
                mapsLink="https://goo.gl/maps/Mfo9v9Go1TzzSTzS9"
                mapsQuery="Akuston+Lazowik+Eugeniusz+Dzialdowo"
              >
                Gabinet nr. 1<br/>
                Nidzicka 10, 13-200 Działdowo<br/>
                <br/>
                wt. 13:00 - 16:00
              </Location>

              <Location
                city="Susz"
                mapsLink="https://goo.gl/maps/HzrDGZMpKZ6NMnYMA"
                mapsQuery="Akuston+Lazowik+Eugeniusz+Wybickiego+Susz"
              >
                Ośrodek Zdrowia Medyk, gabinet nr. 16<br/>
                Wybickiego 9, 14-240 Susz<br/>
                <br/>
                pon. 13:00 - 15:00
              </Location>

              <Location
                city="Lubawa"
                mapsLink="https://goo.gl/maps/MEJEjVscZm8CVspW9"
                mapsQuery="Miejski+Osrodek+Zdrowia+Lubawa"
              >
                Miejski Ośrodek Zdrowia, gabinet nr. 114<br/>
                Rzepnikowskiego 20, 14-260 Lubawa<br/>
                <br/>
                śr. 15:00 - 16:00
              </Location>

              <Location
                city="Nowe Miasto Lubawskie"
                mapsLink="https://goo.gl/maps/kwTUdAFhAYu5mhCc8"
                mapsQuery="Przychodnia+Lekarska+Eskulap+Nowe+Miasto+Lubawskie"
              >
                NZOZ Eskulap, gabinet nr. 2<br/>
                Grunwaldzka 3a 13-300, Nowe Miasto Lubawskie<br/>
                <br/>
                czw. 15:00 - 17:00
              </Location>
            </Grid>
          </Container>

          <Divider my={16} />

          <Container>
            <Box maxW="60em" mx="auto">
              <Heading fontWeight="semibold" textAlign="center" mb="2em" id="faq">
                Często zadawane pytania
              </Heading>

              <Accordion allowToggle={true} defaultIndex={[]}>
                <AccordionItem>
                  <Question>
                    Czy ubytek słuchu w moim wieku jest czymś normalnym?
                  </Question>
                  <Answer>
                    Każdy kto ma problemy ze słyszeniem lub rozumieniem mowy, powinien zgłosić się na badanie słuchu.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Dlaczego słyszę, że ludzie mówią, ale ich nie rozumiem?
                  </Question>
                  <Answer>
                    Ubytek słuchu jest skomplikowanym zjawiskiem – nie polega na tym, że wszystko słychać tak samo ciszej.
                    Dźwięki które słyszymy składają się z wielu fal dźwiękowych. Każdą falę charakteryzuje głośność
                    i częstotliwość. Przy ubytku słuchu różne częstotliwości słyszymy ciszej w różnym stopniu. Z tego
                    powodu ubytku słuchu nie można określić jedną liczbą, tak jak niedowidzenia – potrzebny jest wykres.
                    <br/><br/>
                    Podobnie jest z mową: różne głoski zajmują różne fragmenty pasma częstotliwości, więc sam fakt słyszenia
                    dźwięków mowy nie wystarcza do jej zrozumienia. Potrzebne jest równomierne słyszenie wszystkich
                    częstotliwości wykorzystywanych w mowie.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Mam głuchotę czuciowo-nerwową i powiedziano mi, że aparat słuchowy mi nie pomoże.
                  </Question>
                  <Answer>
                    Większość osób korzystających z aparatów słuchowych ma głuchotę czuciowo-nerwową. Prawie zawsze można
                    im pomóc.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Co, jeśli lekarz powiedział mi, że aparaty słuchowe nie pomogą?
                  </Question>
                  <Answer>
                    Lekarz może mieć medyczne powody, aby unikać używania aparatu słuchowego: na przykład stan słuchu można
                    leczyć za pomocą leków lub operacji. Jeśli jednak masz trwały ubytek słuchu, aparaty słuchowe prawie
                    zawsze zapewnią pomoc. Istnieje szeroki zakres poziomów technologii aparatów słuchowych, a niektóre są
                    nawet w stanie zastąpić „głuche ucho”. Powinieneś skonsultować się z protetykiem słuchu w sprawie
                    dostępnych obecnie urządzeń , które mogą ci pomóc.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Czy potrzebuję aparaty słuchowe nawet jeśli jeszcze radzę sobie bez nich?
                  </Question>
                  <Answer>
                    Opóźnianie protezowania słuchu powoduje stopniowe pogarszanie działania kory mózgowej, które trzeba
                    potem mozolnie odbudowywać. Dużo lepiej jest założyć aparaty przy pierwszych oznakach ubytku, aby
                    utrzymać ją w formie.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Powiedziano mi, że mam ubytek słuchu w obu uszach ... czy wystarczy mi jeden aparat?
                  </Question>
                  <Answer>
                    Bez dwóch aparatów będą występowały większe trudności z lokalizacją źródła dźwięku. Ucho nieprotezowane
                    będzie też słabiej stymulowało odpowiedzialną za nie część kory mózgowej, co spowoduje jej stopniową
                    degenerację, co utrudni ewentualne późniejsze aparatowanie tego ucha.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Nie mam problemów z rozmową jeden na jeden, ale chciałbym lepiej rozumieć w grupie ludzi.
                  </Question>
                  <Answer>
                    Aparaty nowej generacji bardzo dobrze redukują zbędne hałasy i pomagają skupić uwagę na rozumieniu
                    właściwego rozmówcy.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Jeśli mieszkam sam, czy muszę nosić aparaty słuchowe codziennie, czy tylko wtedy, gdy mam gości?
                  </Question>
                  <Answer>
                    Regularne noszenie aparatów jest jak trening dla kory słuchowej. Używanie ich w spokojnych sytuacjach
                    ułatwi rozumienie mowy w warunkach  trudnych akustycznie.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Czy trudno jest przyzwyczaić się do aparatów słuchowych?
                  </Question>
                  <Answer>
                    Jak przy każdym innym urządzeniu protetycznym (dwuogniskowe okulary, soczewki kontaktowe) potrzebny
                    jest czas na adaptację. Protetyk słuchu pomoże w jego przejściu.
                  </Answer>
                </AccordionItem>

                <AccordionItem>
                  <Question>
                    Mam przyjaciół , którzy kupili aparaty słuchowe i ich nie noszą. Co zrobić aby u mnie nie powtórzyła
                    się taka sytuacja?
                  </Question>
                  <Answer>
                    W naszym punkcie protetycznym szukamy najlepszego rozwiązania dla pacjenta. Dopasowanie odbywa się
                    z wykorzystaniem nowoczesnego sprzętu REM. Dzięki niemu nie zgadujemy jak powinien być ustawiony aparat
                    tylko „widzimy” czy jest poprawnie ustawiony. W ofercie posiadamy aparaty kilku producentów, możemy więc
                    znaleźć najlepsze rozwiązanie dla każdego pacjenta. Zakup aparatu to ostatni etap, który następuje po
                    upewnieniu się, że sprawdzi się on we wszystkich sytuacjach życiowych.
                  </Answer>
                </AccordionItem>
              </Accordion>
            </Box>
          </Container>

          <Divider my={16} />

          <Box as="footer" mt={12} textAlign="center">
            <Text fontSize="sm">
              © Akuston Łazowik Eugeniusz | aparaty@akuston.eu
            </Text>
          </Box>

        </Box>
      </ColorModeProvider>
    </ThemeProvider>
  ]);
};

export default IndexPage;
